<div *ngIf="loading$ | async" class="loading-div">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>
<div [innerHTML]="'User.Profile.InfoText' | translate"></div>
<div class="alert alert-success" *ngIf="submitted" [innerHTML]="'User.Profile.SavedSuccessful' | translate"></div>
<form
  *ngIf="!submitted && !(loading$ | async)"
  [formGroup]="userProfileForm"
  (ngSubmit)="save()"
  class="mt-lg-3"
  autocomplete="off">
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.FirstName' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        readonly
        type="text"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        value="{{ firstname }}"
        maxlength="100" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.LastName' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        readonly
        type="text"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        value="{{ lastname }}"
        maxlength="100" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.EMail' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        readonly
        type="text"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        value="{{ email }}"
        maxlength="100" />
    </div>
  </div>
  <div class="form-group row mb-5" *ngIf="!isLoggedInWithIDP">
    <div class="col-sm-3 col-form-label text-sm-right pr-0"></div>
    <div class="col-sm-9">
      <button type="button" class="btn btn-secondary btn-bold px-4" (click)="changePersonalInfo()">
        <i class="fa fa-external-link mr-1"></i>
        {{ 'User.Profile.ChangePersonalInfo' | translate }}
      </button>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.Language' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <select formControlName="language" class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block">
        <option *ngFor="let languageId of translate.getLangs()" [ngValue]="languageId">
          {{ 'Languages.' + languageId | translate }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.InstitutionPractice' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="institutionName"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        placeholder="{{ 'Global.InstitutionPractice' | translate }}"
        maxlength="200" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.Title' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="title"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="100" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.FunctionTitle' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="function"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        placeholder="{{ 'Global.FunctionTitle' | translate }}"
        maxlength="1000" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.MobilePhoneNumber' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="mobilePhoneNumber"
        (input)="mobilePhoneNumberChange($event)"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        placeholder="{{ 'Global.MobilePhoneNumber' | translate }}"
        maxlength="30" />
    </div>
    <div
      *ngIf="f.mobilePhoneNumber.invalid && (f.mobilePhoneNumber.dirty || f.mobilePhoneNumber.touched)"
      class="alert alert-danger">
      <div *ngIf="f.mobilePhoneNumber.errors?.pattern">{{ 'Global.Errors.MobilePhoneNumberPattern' | translate }}</div>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.Street' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="street"
        class="form-control col-sm-6 col-md-4 col-lg-4 d-inline-block"
        placeholder="{{ 'Global.Street' | translate }}"
        maxlength="200" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.PostalCode' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="postalCode"
        class="form-control col-sm-6 col-md-4 col-lg-1 d-inline-block"
        placeholder="{{ 'Global.PostalCode' | translate }}"
        maxlength="30" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.City' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <input
        type="text"
        formControlName="city"
        class="form-control col-sm-6 col-md-4 col-lg-2 d-inline-block"
        placeholder="{{ 'Global.City' | translate }}"
        maxlength="60" />
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-3 col-form-label text-sm-right pr-0">
      <label for="id-form-field-1" class="mb-0">
        {{ 'Global.Institutions' | translate }}
      </label>
    </div>
    <div class="col-sm-9">
      <mat-selection-list formControlName="institutions" class="mat-list">
        <mat-list-option
          *ngFor="let institution of institutions"
          [value]="institution.id"
          checkboxPosition="before"
          [disabled]="true">
          {{ institution.title }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>
  <ng-container *ngIf="!appComponent.features.simplifiedRegistration">
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label for="id-form-field-1" class="mb-0">
          {{ 'Global.ZsrNumber' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <input
          type="text"
          formControlName="zsrNumber"
          class="form-control col-sm-6 col-md-4 col-lg-2 d-inline-block"
          placeholder="{{ 'Global.ZsrNumber' | translate }}"
          maxlength="100" />
        <div
          *ngIf="f.zsrNumber.invalid && (f.zsrNumber?.dirty || f.zsrNumber?.touched)"
          class="col-sm-4 alert alert-danger">
          <div *ngIf="f.zsrNumber.errors?.pattern">{{ 'Global.Errors.ZsrNumberInvalid' | translate }}</div>
        </div>
        <div [innerHTML]="'Account.Register.ZSRNumberHint' | translate"></div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-3 col-form-label text-sm-right pr-0">
        <label for="id-form-field-1" class="mb-0">
          {{ 'Global.GlnNumber' | translate }}
        </label>
      </div>
      <div class="col-sm-9">
        <input
          type="text"
          formControlName="glnNumber"
          class="form-control col-sm-6 col-md-4 col-lg-2 d-inline-block"
          placeholder="{{ 'Global.GlnNumber' | translate }}"
          maxlength="100" />
        <div
          *ngIf="f.glnNumber.invalid && (f.glnNumber?.dirty || f.glnNumber?.touched)"
          class="col-sm-4 alert alert-danger">
          <div *ngIf="f.glnNumber.errors?.pattern">{{ 'Global.Errors.GlnNumberInvalid' | translate }}</div>
        </div>
        <div [innerHTML]="'Account.Register.GLNNumberHint' | translate"></div>
      </div>
    </div>
  </ng-container>
  <div class="mt-5 border-t-1 bgc-secondary-l4 brc-secondary-l2 py-35 mx-n25">
    <div class="offset-md-3 col-md-9 text-nowrap">
      <button class="btn btn-primary btn-bold px-4" [disabled]="userProfileForm.invalid" type="submit">
        <i class="fa fa-check mr-1"></i>
        {{ 'Global.Save' | translate }}
      </button>
    </div>
  </div>
</form>
