<br />
<h4 class="widget-title lighter">
  <i class="ace-icon fa fa-user orange"></i>
  {{ 'Global.UserInfo' | translate }}
</h4>
<div class="table-responsive">
  <table class="table table-striped table-bordered table-hover editables">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Global.Id' | translate }}</th>
        <th>{{ 'Global.UserName' | translate }}</th>
        <th>{{ 'Global.ApprovedBy' | translate }}</th>
        <th>{{ 'Global.ApprovedDate' | translate }}</th>
        <th>{{ 'Global.LastLogin' | translate }}</th>
        <th>{{ 'Global.MobilePhoneNumber' | translate }}</th>
        <th>{{ 'Global.LockoutEnabled' | translate }}</th>
        <th>{{ 'Global.LockoutEnd' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ rowData.id }}</td>
        <td>{{ rowData.userName }}</td>
        <td>{{ rowData.approveByEmail }}</td>
        <td>{{ rowData.approveDate | date: 'dd.MM.yyyy' }}</td>
        <td>{{ rowData.lastLogin | date: 'dd.MM.yyyy' }}</td>
        <td>{{ rowData.mobilePhoneNumber }}</td>
        <td>{{ rowData.lockoutEnabled }}</td>
        <td>{{ rowData.lockoutEnd | date: 'dd.MM.yyyy' }}</td>
      </tr>
    </tbody>
  </table>
  <table class="table table-striped table-bordered table-hover editables">
    <thead class="thin-border-bottom">
      <tr>
        <th>{{ 'Global.InstitutionPractice' | translate }}</th>
        <th>{{ 'Global.Street' | translate }}</th>
        <th>{{ 'Global.PostalCode' | translate }}</th>
        <th>{{ 'Global.City' | translate }}</th>
        <th>{{ 'Global.ZsrNumber' | translate }}</th>
        <th>{{ 'Global.GlnNumber' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{{ rowData.institutionName }}</td>
        <td>{{ rowData.street }}</td>
        <td>{{ rowData.postalCode }}</td>
        <td>{{ rowData.city }}</td>
        <td>{{ rowData.zsrNumber }}</td>
        <td>{{ rowData.glnNumber }}</td>
      </tr>
    </tbody>
  </table>
</div>
