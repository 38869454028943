<form class="form-horizontal" [formGroup]="form" autocomplete="off">
  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Title' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="title"
        class="form-control"
        placeholder="{{ 'Global.Title' | translate }}"
        maxlength="100" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.FirstName' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="firstName"
        class="form-control"
        placeholder="{{ 'Global.FirstName' | translate }}"
        maxlength="100" />
      <div *ngIf="f.firstName.invalid && (f.firstName.dirty || f.firstName.touched)" class="alert alert-danger">
        <div *ngIf="f.firstName.errors?.required">{{ 'Account.Errors.FirstNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.LastName' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="lastName"
        class="form-control"
        placeholder="{{ 'Global.LastName' | translate }}"
        maxlength="100" />
      <div *ngIf="f.lastName.invalid && (f.lastName.dirty || f.lastName.touched)" class="alert alert-danger">
        <div *ngIf="f.lastName.errors?.required">{{ 'Account.Errors.LastNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row" *ngIf="data.accountType == 0">
    <label class="col-sm-4 col-form-label">{{ 'Global.UserName' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="userName"
        class="form-control"
        placeholder="{{ 'Global.UserName' | translate }}"
        maxlength="256"
        readonly />
      <div *ngIf="f.userName.invalid && (f.userName.dirty || f.userName.touched)" class="alert alert-danger">
        <div *ngIf="f.userName.errors?.required">{{ 'Account.Errors.UserNameRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.EMail' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="email"
        class="form-control"
        placeholder="{{ 'Global.EMail' | translate }}"
        maxlength="256"
        readonly />
      <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="alert alert-danger">
        <div *ngIf="f.email.errors?.required">{{ 'Account.Errors.EmailRequired' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.FunctionTitle' | translate }}</label>
    <div class="col-sm-8">
      <textarea
        rows="2"
        formControlName="function"
        class="form-control"
        placeholder="{{ 'Global.FunctionTitle' | translate }}"
        maxlength="1000"></textarea>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.InstitutionPractice' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="institutionName"
        class="form-control"
        placeholder="{{ 'Global.InstitutionPractice' | translate }}"
        maxlength="200" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Street' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="street"
        class="form-control"
        placeholder="{{ 'Global.Street' | translate }}"
        maxlength="200" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.PostalCode' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="postalCode"
        class="form-control"
        placeholder="{{ 'Global.PostalCode' | translate }}"
        maxlength="30" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.City' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="city"
        class="form-control"
        placeholder="{{ 'Global.City' | translate }}"
        maxlength="60" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.Institutions' | translate }}</label>
    <div class="col-sm-8">
      <mat-selection-list formControlName="institutions" class="mat-list">
        <mat-list-option *ngFor="let institution of institutions" [value]="institution.id" checkboxPosition="before">
          {{ institution.title }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div class="form-group row" *ngIf="data.accountType == 0">
    <label class="col-sm-4 col-form-label">{{ 'Global.Insurances' | translate }}</label>
    <div class="col-sm-8">
      <mat-selection-list formControlName="insurances" class="mat-list">
        <mat-list-option *ngFor="let insurance of insurances" [value]="insurance.id" checkboxPosition="before">
          {{ insurance.title }}
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div class="form-group row" *ngIf="data.accountType == 0">
    <label class="col-sm-4 col-form-label">{{ 'Global.MobilePhoneNumber' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="mobilePhoneNumber"
        (input)="mobilePhoneNumberChange($event)"
        class="form-control"
        placeholder="{{ 'Global.MobilePhoneNumber' | translate }}"
        maxlength="30" />
      <div
        *ngIf="f.mobilePhoneNumber.invalid && (f.mobilePhoneNumber.dirty || f.mobilePhoneNumber.touched)"
        class="alert alert-danger">
        <div *ngIf="f.mobilePhoneNumber.errors?.pattern">
          {{ 'Global.Errors.MobilePhoneNumberPattern' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.ZsrNumber' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="zsrNumber"
        class="form-control"
        placeholder="{{ 'Global.ZsrNumber' | translate }}"
        maxlength="100" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.GlnNumber' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="glnNumber"
        class="form-control"
        placeholder="{{ 'Global.GlnNumber' | translate }}"
        maxlength="100" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.EmployedFrom' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="employedFrom"
        class="form-control"
        placeholder="{{ 'Global.EmployedFrom' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label">{{ 'Global.EmployedUntil' | translate }}</label>
    <div class="col-sm-8">
      <input
        type="text"
        formControlName="employedUntil"
        class="form-control"
        placeholder="{{ 'Global.EmployedUntil' | translate }}"
        bsDatepicker
        [bsConfig]="{ adaptivePosition: true }" />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-4 col-form-label" *ngIf="data.accountType == 0">{{ 'Global.Groups' | translate }}</label>
    <label class="col-sm-4 col-form-label" *ngIf="data.accountType == 1">{{
      'Global.EmployeeGroups' | translate
    }}</label>
    <div class="col-sm-8">
      <mat-selection-list formControlName="groups" class="mat-list">
        <mat-list-option
          *ngFor="let group of groups"
          [value]="group.id"
          checkboxPosition="before"
          [disabled]="(canIssueGroup(group) | async) == false">
          <div class="row">
            <div class="col-10">
              {{ group.name }}
            </div>
            <div class="col-1">
              <ng-template #groupRoleDescriptionTemplate>
                <div style="white-space: pre">{{ getGroupRoleDescription(group.id) }}</div>
              </ng-template>
              <span [tooltip]="groupRoleDescriptionTemplate" placement="left" container="body">{{
                'Global.Info' | translate
              }}</span>
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </div>

  <div class="form-group row" *ngIf="data.accountType == 0">
    <label class="col-sm-4 col-form-label">{{ 'Global.LockoutEnabled' | translate }}</label>
    <div class="col-sm-8">
      <input type="checkbox" formControlName="lockoutEnabled" />
    </div>
  </div>

  <div class="form-group row" *ngIf="data.accountType == 0">
    <label class="col-sm-4 col-form-label">{{ 'Global.LockoutEnd' | translate }}</label>
    <div class="col-sm-8 d-flex">
      <button type="button" class="btn btn-sm mb-1" (click)="clearLockoutEnd()" [tooltip]="'Global.Delete' | translate">
        <div class="red fa fa-trash"></div>
      </button>
      <input
        type="datetime-local"
        formControlName="lockoutEnd"
        class="form-control"
        placeholder="{{ 'Global.LockoutEnd' | translate }}" />
    </div>
  </div>
</form>
